//
// Component: Nav
//

.nav-pills {
  .nav-link {
    color: $gray-600;
    border-radius: 0;
    &:not(.active):hover {
      color: theme-color("primary");
    }
  }

  .nav-item {
    &.dropdown.show {
      .nav-link:hover {
        color: $dropdown-link-active-color;
      }
    }
  }
}

// Vertical Tabs
.nav-tabs.flex-column {
  border-bottom: 0;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link {
    border-bottom-left-radius: $nav-tabs-border-radius;
    border-top-right-radius: 0;
    margin-right: -$nav-tabs-border-width;

    @include hover-focus() {
      border-color: $gray-200 transparent $gray-200 $gray-200;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-color: $gray-300 transparent $gray-300 $gray-300;
  }

  &.nav-tabs-right {
    border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
    border-right: 0;

    .nav-link {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: $nav-tabs-border-radius;
      border-top-left-radius: 0;
      border-top-right-radius: $nav-tabs-border-radius;
      margin-left: -$nav-tabs-border-width;

      @include hover-focus() {
        border-color: $gray-200 $gray-200 $gray-200 transparent;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      border-color: $gray-300 $gray-300 $gray-300 transparent;
    }
  }
}

.navbar-no-expand {
  flex-direction: row;

  .nav-link {
    padding-left: $navbar-nav-link-padding-x;
    padding-right: $navbar-nav-link-padding-x;
  }

  .dropdown-menu {
    position: absolute;
  }
}

// Color variants
@each $color, $value in $theme-colors {
  @if $color == dark or $color == light {
    .navbar-#{$color} {
      background-color: $value;
      @if $color == dark {
        border-color: lighten($dark, 10%);
      }
    }
  }
}

@each $color, $value in $theme-colors {
  @if $color != dark and $color != light {
    @include navbar-variant($color, $value);
  }
}

@each $color, $value in $colors {
  @include navbar-variant($color, $value);
}

.navbar-nav-not-expanded {
  flex-direction: row;

  .dropdown-menu {
    position: absolute;
  }

  .nav-link {
    padding-right: $navbar-nav-link-padding-x;
    padding-left: $navbar-nav-link-padding-x;
  }
}

@include dark-mode() {
  .nav-pills .nav-link {
    color: $gray-400;
  }

  .nav-tabs {
    border-color: lighten($dark, 15%);

    .nav-link:focus,
    .nav-link:hover {
      border-color: lighten($dark, 15%);
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      background-color: $dark;
      border-color: lighten($dark, 15%) lighten($dark, 15%) transparent
        lighten($dark, 15%);
      color: $white;
    }

    &.flex-column {
      .nav-item.show .nav-link,
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-color: lighten($dark, 15%) transparent lighten($dark, 15%)
            lighten($dark, 15%);
        }
        &:focus,
        &:hover {
          background-color: lighten($dark, 5%);
        }
      }
      &.nav-tabs-right {
        border-color: lighten($dark, 15%);
        .nav-link {
          &.active,
          &:focus,
          &:hover {
            border-color: lighten($dark, 15%) lighten($dark, 15%)
              lighten($dark, 15%) transparent;
          }
        }
      }
    }
  }

  // Color variants
  @each $color, $value in $theme-colors-alt {
    @if $color == dark or $color == light {
      .navbar-#{$color} {
        background-color: $value;
        @if $color == dark {
          border-color: lighten($dark, 10%);
        }
      }
    }
  }

  @each $color, $value in $theme-colors-alt {
    @if $color != dark and $color != light {
      @include navbar-variant($color, $value);
    }
  }

  @each $color, $value in $colors-alt {
    @include navbar-variant($color, $value);
  }
}
