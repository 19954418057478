.p-datatable-filter input {
  height: 30px; /* Adjust the height as needed */
  background-color: orange;
}

.p-inputtext {
  height: 30px; /* Adjust the height */
  padding: 0.5rem; /* Adjust the padding if necessary */
}

td {
  padding: 3px !important;
}

.p-row-odd {
  background-color: #eeeeee !important;
  color: black !important;
}

.text-sm:hover {
  background-color: black !important;
  color: white !important;
}
